const brands = [
  'Toyota',
  'Mazda',
  'Kia',
  'Ford',
  'Hyundai',
  'Mitsubishi',
  'Volkswagen',
  'Subaru',
  'Nissan',
  'Honda',
];

export default brands;
