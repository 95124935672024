import Link from 'next/link';

import Card from '@components/common/Card/Card';
import SectionWithContainer from '@components/common/SectionWithContainer/SectionWithContainer';

const HowSimplrWorkSection = () => {
  return (
    <SectionWithContainer
      containerProps={{ className: 'relative max-w-screen-xl' }}
    >
      <div className="bg-blue-5 border-l-6 border-t-6 border-blue-100 rounded-tl-[100px] rounded-br-[100px] h-[250px] absolute left-0 top-0 w-full" />
      <div className="lg:mb-40 px-6 py-11 lg:grid lg:grid-cols-2 relative z-10">
        <div className="lg:pr-3 w-full lg:max-w-[495px] mb-5">
          <Card
            className="overflow-hidden w-full h-[330px] max-w-full relative xl:-mb-[200px]"
            borderRadius={['4xl', false, '4xl', false]}
          >
            <img
              className="object-cover h-full"
              src="/images/gets_you_behind_the_wheel.webp"
              alt="gets you behind the wheel fast no matter where you are or why you need it."
              width={483}
              height={323}
            />
          </Card>
        </div>
        <div className="text-dark-blue-100 text-3xl font-light lg:pl-3">
          <span className="font-medium">
            Simplr. gets you behind the wheel fast{' '}
          </span>
          no matter where you are or why you need it.
          <Link href="/about" prefetch={false}>
            <a className="text-base text-right block text-coral-100 font-medium mt-8 lg:mr-16">
              How Simplr. works {'>'}
            </a>
          </Link>
        </div>
      </div>
    </SectionWithContainer>
  );
};

export default HowSimplrWorkSection;
