import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
} from 'react';

import ArrowIcon from '@components/common/Icons/ArrowIcon/ArrowIcon';
import cx from 'classnames';

import s from './Slider.module.css';

interface SliderProps {
  children: ReactNode;
  hideScroll?: boolean;
  styles?: {
    navigationContainer?: CSSProperties;
    navigationBackButton?: CSSProperties;
    navigationNextButton?: CSSProperties;
  };
}

const Slider = ({
  children,
  hideScroll = true,
  styles = {
    navigationContainer: undefined,
    navigationBackButton: undefined,
    navigationNextButton: undefined,
  },
}: PropsWithChildren<SliderProps>) => {
  const scroller = useRef<HTMLDivElement>(null);

  const scrollRight = useCallback((e: any) => {
    e.preventDefault();
    const sliderSize = scroller?.current?.children[0].clientWidth || 0;
    scroller?.current?.scrollTo(scroller?.current?.scrollLeft + sliderSize, 0);
  }, []);

  const scrollLeft = useCallback((e: any) => {
    e.preventDefault();
    const sliderSize = scroller?.current?.children[0].clientWidth || 0;
    scroller?.current?.scrollTo(scroller?.current?.scrollLeft - sliderSize, 0);
  }, []);

  return (
    <>
      <div
        ref={scroller}
        className={cx(s.scroller, { [s.hideScroll]: hideScroll })}
      >
        {children}
      </div>
      <div className={s.navigation}>
        <div style={styles.navigationContainer}>
          <button
            type="button"
            style={styles.navigationBackButton}
            onClick={scrollLeft}
          >
            <ArrowIcon className="w-6 h-6 text-grey-60" />
          </button>
          <button
            type="button"
            style={styles.navigationNextButton}
            onClick={scrollRight}
          >
            <ArrowIcon className="rotate-180 w-6 h-6 text-grey-60" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Slider;
