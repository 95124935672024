import { forwardRef, SVGProps } from 'react';

export default forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  function CircleChevronRightIcon() {
    return (
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_5740_33"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="21"
          height="21"
        >
          <rect
            x="0.728516"
            y="0.834229"
            width="20"
            height="20"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_5740_33)">
          <path
            d="M10.7284 14.1676L14.0617 10.8342L10.7284 7.50092L9.56169 8.66758L10.895 10.0009H7.39502V11.6676H10.895L9.56169 13.0009L10.7284 14.1676ZM10.7284 19.1676C9.57557 19.1676 8.49224 18.9488 7.47835 18.5113C6.46446 18.0738 5.58252 17.4801 4.83252 16.7301C4.08252 15.9801 3.48877 15.0981 3.05127 14.0842C2.61377 13.0704 2.39502 11.987 2.39502 10.8342C2.39502 9.68147 2.61377 8.59814 3.05127 7.58425C3.48877 6.57036 4.08252 5.68842 4.83252 4.93842C5.58252 4.18842 6.46446 3.59467 7.47835 3.15717C8.49224 2.71967 9.57557 2.50092 10.7284 2.50092C11.8811 2.50092 12.9645 2.71967 13.9784 3.15717C14.9922 3.59467 15.8742 4.18842 16.6242 4.93842C17.3742 5.68842 17.9679 6.57036 18.4054 7.58425C18.8429 8.59814 19.0617 9.68147 19.0617 10.8342C19.0617 11.987 18.8429 13.0704 18.4054 14.0842C17.9679 15.0981 17.3742 15.9801 16.6242 16.7301C15.8742 17.4801 14.9922 18.0738 13.9784 18.5113C12.9645 18.9488 11.8811 19.1676 10.7284 19.1676ZM10.7284 17.5009C12.5895 17.5009 14.1659 16.8551 15.4575 15.5634C16.7492 14.2717 17.395 12.6954 17.395 10.8342C17.395 8.97314 16.7492 7.39675 15.4575 6.10508C14.1659 4.81342 12.5895 4.16758 10.7284 4.16758C8.86724 4.16758 7.29085 4.81342 5.99919 6.10508C4.70752 7.39675 4.06169 8.97314 4.06169 10.8342C4.06169 12.6954 4.70752 14.2717 5.99919 15.5634C7.29085 16.8551 8.86724 17.5009 10.7284 17.5009Z"
            fill="white"
          />
        </g>
      </svg>
    );
  },
);
