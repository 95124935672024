import { useCallback, useMemo, useState } from 'react';

import Card, { CardProps } from '@components/common/Card/Card';
import Phone from '@components/common/Phone/Phone';
import SectionWithContainer from '@components/common/SectionWithContainer/SectionWithContainer';
import cx from 'classnames';
import { Colors } from 'enums/colors';

const REASONS_WHY = [
  {
    id: 1,
    title: 'Largest range of cars',
    description:
      'As part of the country’s biggest motoring retail group, Simplr. has literally thousands of cars to choose from.',
    imagePhone: '/images/why_subscription_largest_range_of_cars.webp',
    reverse: false,
    backgroundColorPhone: 'bg-coral-100',
    textColorPhone: 'text-coral-10',
    textPhone: 'Find the car to suit your lifestyle...',
  },
  {
    id: 2,
    title: 'One flat weekly payment',
    description:
      'Cover the usual motoring costs in one hit – including rego, insurance, maintenance and roadside assist.',
    imagePhone: '/images/why_subscription_one_flat_weekly.webp',
    reverse: true,
    backgroundColorPhone: 'bg-gold-100',
    textColorPhone: 'text-coral-10',
    textPhone: 'Spend more time on having fun...',
  },
  {
    id: 3,
    title: 'Fast and flexible',
    description:
      'Change your car to suit your lifestyle. We have cars ready to go from locations across the country.',
    imagePhone: '/images/why_subscription_fast_and_flexible.webp',
    reverse: false,
    backgroundColorPhone: 'bg-dark-blue-80',
    textColorPhone: 'text-blue-20',
    textPhone: 'The Simplr. way to get behind the wheel...',
  },
  {
    id: 4,
    title: 'No lock-in contracts',
    description:
      'Subscribe for as long as you need the car. No long-term commitment or contractual hand-cuffs.',
    imagePhone: '/images/why_subscription_not_lock_in_contracts.webp',
    reverse: true,
    backgroundColorPhone: 'bg-coral-100',
    textColorPhone: 'text-coral-10',
    textPhone: 'Unlock more time to do your own thing...',
  },
];
const WhySimplrSection = () => {
  const [selectedID, setSelectedID] = useState(REASONS_WHY[0].id);

  const selectedReasonWhy = useMemo(
    () => REASONS_WHY.find(({ id }) => id === selectedID),
    [selectedID],
  );

  const renderPhone = useCallback(() => {
    if (!selectedReasonWhy) {
      return;
    }
    const phoneContent = [
      <div
        className={cx(
          'flex-1',
          selectedReasonWhy.backgroundColorPhone,
          selectedReasonWhy.textColorPhone,
        )}
        key={`${selectedReasonWhy.id}-${selectedReasonWhy.textPhone}`}
      >
        <p className="text-3xl font-medium md:text-[36px] p-4">
          {selectedReasonWhy.textPhone}
        </p>
      </div>,
      <img
        key={`${selectedReasonWhy.id}-${selectedReasonWhy.imagePhone}`}
        className="px-6 py-4 md:p-0 object-cover flex-1"
        src={selectedReasonWhy.imagePhone}
        alt="Large range of cars"
      />,
    ];

    return (
      <Phone>
        {selectedReasonWhy.reverse ? phoneContent.reverse() : phoneContent}
      </Phone>
    );
  }, [selectedReasonWhy]);

  return (
    <SectionWithContainer className="hidden py-14  md:block lg:block">
      <h2 className="font-light text-center text-dark-blue-100">
        Why Simplr. Car Subscription?
      </h2>
      <p className="pt-4 font-light text-center text-grey-100">
        All your usual motoring costs covered in one weekly payment
      </p>
      <div className="flex items-center py-10">
        <div className="flex flex-1 justify-center">{renderPhone()}</div>

        <div className="space-y-5 flex-[1.5]">
          {REASONS_WHY.map(({ id, title, description }) => (
            <ReasonWhySimplr
              color={id === selectedID ? Colors.coral : Colors.blue}
              key={id}
              onClick={() => setSelectedID(id)}
              title={title}
              description={description}
            />
          ))}
        </div>
      </div>
    </SectionWithContainer>
  );
};

interface ReasonWhySimplrProps extends CardProps {
  title: string;
  description: string;
  color: Colors;
}
const ReasonWhySimplr = ({
  title,
  description,
  color,
  className,
  ...props
}: ReasonWhySimplrProps) => {
  return (
    <Card
      {...props}
      borderRadius={[false, 'lg', '4xl', false]}
      className={cx(
        className,
        `bg-grey-5 w-4/5 px-8 py-4 border-l-8 cursor-pointer transition-colors shadow-card`,
        {
          'border-coral-100': color === Colors.coral,
          'border-blue-100': color === Colors.blue,
        },
      )}
    >
      <p
        className={cx(`font-medium text-2xl pb-2 transition-colors`, {
          'text-coral-100': color === Colors.coral,
          'text-blue-100': color === Colors.blue,
        })}
      >
        {title}
      </p>
      <p className="text-grey-100">{description}</p>
    </Card>
  );
};

export default WhySimplrSection;
