import { BadgeCheckIcon } from '@components/common/Icons';
import SectionWithContainer from '@components/common/SectionWithContainer/SectionWithContainer';

const BenefitsSection = () => {
  return (
    <SectionWithContainer>
      <div className="flex flex-col lg:flex-row items-center lg:justify-evenly">
        <div className="inline-block text-left lg:contents">
          <Benefit text="Largest range of cars" />
          <Benefit text="No lock-in contracts" />
          <Benefit text="Fast and flexible" />
          <Benefit text="One flat monthly payment" />
        </div>
      </div>
    </SectionWithContainer>
  );
};

const Benefit = ({ text }: { text: string }) => {
  return (
    <div className="flex flex-row items-center">
      <BadgeCheckIcon className="h-[40px] w-[40px] text-coral-100" />
      <span className="text-grey-100 ml-2 font-medium lg:font-light">
        {text}
      </span>
    </div>
  );
};

export default BenefitsSection;
