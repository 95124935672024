import CircleChevronRightIcon from '@components/common/Icons/CircleChevronRightIcon/CircleChevronRightIcon';
import { Colors } from 'enums/colors';

import Button from '../../../common/Button/Button';
import CheckItemsBenefitsBanner from '../components/CheckItemsBenefitsBanner';

const NewCarBanner = () => {
  return (
    <div className="mx-auto flex flex-col-reverse items-center justify-start md:bg-none lg:h-[450px] lg:items-start lg:justify-center lg:bg-[url('/images/new-car.png')] lg:bg-cover lg:bg-left lg:bg-no-repeat lg:px-12 lg:py-5 xl:h-[500px] xl:bg-cover xl:bg-right xl:py-4 2xl:h-[545px] ">
      <div className="mt-[14px] flex flex-col items-center justify-center md:w-[65%]  lg:mt-0 lg:w-[49%] lg:items-start xl:w-[40%]">
        <h2 className="text-center leading-none text-blue-100 md:mb-3 md:text-3xl md:leading-10 lg:mb-0 lg:text-left lg:text-4xl lg:leading-none">
          New car.
        </h2>
        <h2 className="text-center leading-none text-blue-100 md:mb-3 md:text-3xl md:leading-10 lg:mb-0 lg:text-left lg:text-4xl lg:leading-none">
          No worries.
        </h2>
        <p className="mt-4 w-4/6 text-center text-[#797979] md:mb-8 md:w-4/6 lg:w-4/6 lg:text-left  lg:text-[20px] lg:leading-normal xl:text-[24px]">
          Why buy when you can subscribe?
        </p>
        <div className="flex flex-col-reverse items-center lg:mt-5 lg:w-full lg:flex-col lg:items-start">
          <CheckItemsBenefitsBanner
            items={[
              'One fully-inclusive weekly payment',
              'Generous mileage allowance',
              'All maintenance covered ',
            ]}
          />
          <div className="mt-5 md:mt-0 md:mb-7 lg:mt-6 lg:flex lg:items-end lg:justify-start">
            <Button
              className="flex items-center justify-center"
              color={Colors.green}
              href="/contact-us"
            >
              <span className="mr-2">ENQUIRE TODAY</span>
              <CircleChevronRightIcon className="h-5 w-5" />
            </Button>
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <img
          className="w-full object-cover object-center p-0"
          src="/images/new-car-mob.png"
          alt="Family enjoying vacation with a car in a beach"
          width={866}
          height={410}
        />
      </div>
      <div className="hidden lg:block lg:w-4/6" />
    </div>
  );
};

export default NewCarBanner;
