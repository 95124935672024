import Link from 'next/link';
import { useState, useEffect, useMemo } from 'react';
import { Rating } from 'react-simple-star-rating';

import Button from '@components/common/Button/Button';
import SectionWithContainer from '@components/common/SectionWithContainer/SectionWithContainer';
import Slider from '@components/common/Slider/Slider';
import axios from 'axios';

type PlaceReview = google.maps.places.PlaceReview & {
  rating: number;
};

const CustomerReviewCard = function ({
  review,
  key,
  id,
}: {
  review: PlaceReview;
  key: string;
  id: string;
}) {
  const finalText = useMemo(() => {
    if (review.text.length <= 100) {
      return review.text;
    }
    return `${review.text.slice(0, 100)}...`;
  }, [review.text]);

  return (
    <div
      className="flex flex-col relative bg-white min-w-[270px] lg:h-80 text-center p-5"
      key={key}
      id={id}
    >
      <div className="hidden lg:block">
        <div className="flex flex-col items-center pb-2 lg:pb-0">
          <img
            src={review.profile_photo_url}
            alt={review.author_name}
            width={72}
            height={72}
          />

          <div className="pl-5 text-left lg:text-center lg:pl-0">
            <p className="py-1">{review.author_name}</p>
            <div>
              <Rating
                ratingValue={0}
                initialValue={review.rating}
                size={15}
                iconsCount={5}
                readonly
                fullStyle={{ display: 'flex', flexDirection: 'row' }}
                emptyStyle={{ display: 'flex', flexDirection: 'row' }}
              />
            </div>
          </div>
          <p className="flex items-end justify-end flex-grow pb-4 text-xs text-grey-80 lg:hidden">
            {review.relative_time_description}
          </p>
        </div>
      </div>

      {/* mobile only */}
      <div className="flex pb-3 lg:pb-0 lg:hidden">
        <img
          className="object-contain w-[72px] h-[72px]"
          src={review.profile_photo_url}
          alt={review.author_name}
        />

        <div className="pl-5 text-left lg:text-center lg:pl-0">
          <p className="py-2">{review.author_name}</p>
          <Rating
            ratingValue={0}
            initialValue={review.rating}
            size={15}
            iconsCount={5}
            readonly
            fullStyle={{ display: 'flex', flexDirection: 'row' }}
            emptyStyle={{ display: 'flex', flexDirection: 'row' }}
          />
        </div>
        <p className="flex items-end justify-end flex-grow pb-4 text-xs text-grey-80 lg:hidden">
          {review.relative_time_description}
        </p>
      </div>
      <p className="pt-2 text-sm font-light text-left text-grey-100 lg:text-center">
        {finalText}
      </p>
      <div className="absolute left-0 right-0 items-center justify-center hidden space-x-2 bottom-5 lg:flex">
        <img
          src="/images/google-icon.png"
          alt="Google"
          width={25}
          height={25}
        />
        <p className="text-xs text-center text-grey-80">
          {review.relative_time_description}
        </p>
      </div>
    </div>
  );
};

const CustomerReviewSection = () => {
  const [reviews, setReviews] = useState<PlaceReview[]>([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const { data: reviews }: { data: PlaceReview[] } = await axios.get(
        '/api/google-reviews',
      );

      const filterDataByRating = (reviews || []).filter(
        review => review.rating && review.rating >= 4,
      );

      setReviews(filterDataByRating);
      setLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) return <p>Loading...</p>;

  return (
    <SectionWithContainer className="bg-[#F7FCFF]">
      <h2 className="pb-10 font-light text-center text-dark-blue-100">
        What our customers are saying...
      </h2>
      {!!reviews.length && (
        <div className="hidden lg:block">
          <Slider
            styles={{
              navigationBackButton: { top: '-180px', left: '5px' },
              navigationNextButton: { top: '-180px', right: '5px' },
            }}
          >
            {reviews.map(review => (
              <CustomerReviewCard
                key={review.author_name}
                review={review}
                id={review.author_name}
              />
            ))}
          </Slider>
        </div>
      )}
      {/* mobile only */}
      <div className="block space-y-4 lg:hidden">
        {reviews.map(review => (
          <CustomerReviewCard
            key={review.author_name}
            review={review}
            id={review.author_name}
          />
        ))}
      </div>
      <div className="flex justify-center pt-8 lg:pt-6">
        <Link
          prefetch={false}
          href="https://g.page/r/CVT3jn_Qd7uJEAg/review"
          passHref
        >
          <Button className="text-center" target="_blank">
            LEAVE A REVIEW
          </Button>
        </Link>
      </div>
    </SectionWithContainer>
  );
};

export default CustomerReviewSection;
