import Link from 'next/link';

import Button from '@components/common/Button/Button';
import Card from '@components/common/Card/Card';
import SectionWithContainer from '@components/common/SectionWithContainer/SectionWithContainer';
import cx from 'classnames';
import { Colors } from 'enums/colors';

const MonthlyCostSection = () => {
  return (
    <SectionWithContainer className="bg-grey-5">
      <div className="lg:grid lg:grid-cols-2">
        <div className="text-center lg:text-left pb-10 lg:pb-0">
          <h2 className="text-dark-blue-100 font-light">
            Compare your
            <br />
            monthly costs
          </h2>
          <p className="subtitle text-[24px] pt-8 lg:pr-44">
            Car subscription with Simplr. can be more cost effective than
            leasing
          </p>
          <Link prefetch={false} href="/subscription-cars" passHref>
            <Button className="w-40 mt-16 hidden lg:block" color={Colors.green}>
              BROWSE CARS
            </Button>
          </Link>
        </div>
        <Card
          className="bg-white pl-2 lg:pl-5 overflow-hidden"
          borderRadius={['lg', 'lg', '4xl', 'lg']}
        >
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="border-b border-dark-blue-100"></th>
                <th className="border-b border-dark-blue-100 text-dark-blue-100 font-medium pt-6 pb-2 px-1.5 lg:px-5">
                  Standard
                  <br />
                  Lease
                </th>
                <th className="bg-blue-100 font-medium text-white pt-6 pb-2 px-1.5 lg:px-0">
                  Simplr.
                  <br />
                  Subscription
                </th>
              </tr>
            </thead>
            <tbody>
              {[
                'Flexible contract term',
                'Switch cars when you like',
                'No depreciation',
                'Comprehensive insurance',
                'Free maintenance and servicing',
                'Free roadside assistance',
                'Free loyalty rewards',
              ].map((text, index, arr) => (
                <tr key={text}>
                  <td
                    className={cx(
                      'text-dark-blue-100 font-medium text-sm lg:text-base pt-2',
                      {
                        'pb-4': index + 1 === arr.length,
                      },
                    )}
                  >
                    {text}
                  </td>
                  <td
                    className={cx('text-center', {
                      'pb-4': index + 1 === arr.length,
                    })}
                  >
                    <img
                      className="ml-auto mr-auto"
                      src="/images/multiplication.png"
                      alt="multiplication"
                      width={30}
                      height={30}
                    />
                  </td>
                  <td
                    className={cx('text-center bg-blue-100', {
                      'pb-4': index + 1 === arr.length,
                    })}
                  >
                    <img
                      className="ml-auto mr-auto"
                      src="/images/checked-checkbox.png"
                      alt="checked checkbox"
                      width={30}
                      height={30}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
        <div className="flex justify-center lg:hidden">
          <Link prefetch={false} href="/subscription-cars" passHref>
            <Button className="w-40 mt-10 lg:mt-1" color={Colors.green}>
              BROWSE CARS
            </Button>
          </Link>
        </div>
      </div>
    </SectionWithContainer>
  );
};

export default MonthlyCostSection;
