import { FC, HTMLAttributes } from 'react';

import cx from 'classnames';

type PhoneProps = HTMLAttributes<HTMLDivElement>;
const Phone: FC<PhoneProps> = ({ className, children, ...props }) => {
  return (
    <div {...props} className={cx(className, 'flex justify-center relative')}>
      <div className="absolute z-10 flex flex-col top-12 left-5 right-9 bottom-9 rounded-b-xl rounded-t-md overflow-hidden">
        {children}
      </div>
      <img
        className="px-6 py-4 md:p-0"
        src="/images/simplr-white-phone.jpg"
        alt="phone"
        width={310}
        height={617}
      />
    </div>
  );
};

export default Phone;
