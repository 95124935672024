import CircleChevronRightIcon from '@components/common/Icons/CircleChevronRightIcon/CircleChevronRightIcon';
import { Colors } from 'enums/colors';

import Button from '../../../common/Button/Button';
import CheckItemsBenefitsBanner from '../components/CheckItemsBenefitsBanner';

const CheryOmoda5Banner = () => {
  return (
    <div className="flex flex-col-reverse justify-start items-center lg:items-start mx-auto lg:px-12 lg:py-8 lg:h-[450px] xl:h-[500px] 2xl:h-[545px] lg:bg-[url('/images/chery-omoda-5.png')] lg:bg-left lg:bg-cover lg:bg-no-repeat xl:bg-right md:bg-none ">
      <div className="lg:1/2 xl:w-2/5 h-full flex flex-col items-center lg:items-start lg:justify-center mt-[14px] lg:mt-0">
        <h2 className="text-3xl lg:text-4xl text-blue-100 text-center leading-none lg:text-left">
          Chery Omoda 5
        </h2>
        <h4 className="text-[36px] lg:text-3x1 xl:text-[48px] leading-snug lg:leading-none text-blue-100 font-light  text-center lg:text-left">
          only $299 per week
        </h4>
        <p className="text-[#797979] mt-4 lg:text-[20px] xl:text-[24px] w-4/6 md:w-4/6 text-center lg:text-left lg:w-4/6 lg:leading-normal">
          Enjoy life with this luxury SUV for one flexible, all-inclusive weekly
          payment *
        </p>
        <div className="mt-6 lg:mt-5 flex flex-col-reverse items-center lg:items-start lg:flex-col lg:w-full">
          <CheckItemsBenefitsBanner
            items={[
              '500km weekly allowance',
              'Rego and CTP included',
              'SE Queensland only',
            ]}
          />
          <div className="md:mb-6 flex items-end justify-start lg:mt-6">
            <Button
              className="flex items-center justify-center"
              color={Colors.green}
              href="https://chery-offer.simplr.com.au/"
              target="_blank"
            >
              <span className="mr-2">SUBSCRIBE NOW</span>
              <CircleChevronRightIcon className="w-5 h-5" />
            </Button>
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <img
          className="p-0 object-cover object-center w-full"
          src="/images/chery-omoda-5-mob.png"
          alt="Chery Omoda 5 car white"
          width={866}
          height={410}
        />
      </div>
    </div>
  );
};

export default CheryOmoda5Banner;
