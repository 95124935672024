import { NextSeo } from 'next-seo';

import BenefitsSection from '@components/HomePage/BenefitsSection/BenefitsSection';
import BrandSection from '@components/HomePage/BrandSection/BrandSection';
import CarCategoriesSection from '@components/HomePage/CarCategoriesSection/CarCategoriesSection';
import CustomerReviewSection from '@components/HomePage/CustomerReviewsSection/CustomerReviewSection';
import Header from '@components/HomePage/Header/Header';
import HowSimplrWorkSection from '@components/HomePage/HowSimplrWorkSection/HowSimplrWorkSection';
import MonthlyCostSection from '@components/HomePage/MonthlyCostSection/MonthlyCostSection';
import WhySimplrSection from '@components/HomePage/WhySimplrSection/WhySimplrSection';

import s from 'styles/HomePage.module.css';

export default function Home() {
  return (
    <>
      <NextSeo
        title="Simplr: Australia's Largest Range of Subscription Cars"
        description="Simplr car subscriptions include a car, insurance, maintenance, servicing, and roadside assistance for a flat monthly fee. Subscribe and drive today with Simplr!"
      />

      <h1 className={s.hiddenH1TitleForSeo}>
        Choose from Australia's largest range of subscription cars
      </h1>

      <Header />

      <BenefitsSection />

      <BrandSection />

      <WhySimplrSection />

      <HowSimplrWorkSection />

      <MonthlyCostSection />

      <CarCategoriesSection />

      <CustomerReviewSection />
    </>
  );
}
