import Link from 'next/link';

import Button from '@components/common/Button/Button';
import SectionWithContainer from '@components/common/SectionWithContainer/SectionWithContainer';
import Slider from '@components/common/Slider/Slider';
import axios from 'axios';
import { Colors } from 'enums/colors';
import { VehicleCategory } from 'models/stack9/VehicleCategory';
import useSWR from 'swr';

const CarCategoriesSection = () => {
  const { data: carCategories } = useSWR<VehicleCategory[]>(
    '/api/category',
    url => axios.get(url).then(res => res.data),
  );

  return (
    <SectionWithContainer className="text-center">
      <h2 className="font-light text-dark-blue-100 pb-10">
        Find the car that suits your needs
      </h2>
      {carCategories && carCategories?.length > 0 && (
        <>
          <div className="hidden md:block">
            <Slider
              styles={{
                navigationBackButton: { top: '-210px', left: '5px' },
                navigationNextButton: { top: '-210px', right: '5px' },
              }}
            >
              {carCategories.map(category => (
                <CarCategory key={category.display_name} category={category} />
              ))}
            </Slider>
          </div>
          <div className="block md:hidden">
            {carCategories.map(category => (
              <CarCategory key={category.display_name} category={category} />
            ))}
          </div>
        </>
      )}
    </SectionWithContainer>
  );
};

const CarCategory = ({ category }: { category: VehicleCategory }) => {
  const newDollarValue = Number(category.from_dollar_value).toFixed(0);
  return (
    <div className="flex flex-col items-center justify-center mb-10 md:mb-0 min-w-[300px]">
      <p className="text-blue-100 font-medium">
        {category.display_name.toUpperCase()}
      </p>
      <img
        src={category.image?.url}
        alt={category.image?.name}
        width="300px"
        height="200px"
        className="object-scale-down min-h-[150px] md:min-h-[300px]"
      />
      <p className="text-blue-100 font-medium">
        From
        <span className="text-dark-blue-100 align-top font-semibold"> $</span>
        <span className="text-dark-blue-100 text-2xl font-semibold">
          {`${newDollarValue} `}
        </span>
        per week
      </p>
      <Link
        href={`/subscription-cars?vehicle_category=${category.id}`}
        key={category.sequence}
        passHref
      >
        <Button className="mt-4" color={Colors.green}>
          BROWSE {category.display_name.toUpperCase()}
        </Button>
      </Link>
    </div>
  );
};

export default CarCategoriesSection;
