import Link from 'next/link';

import CircleChevronRightIcon from '@components/common/Icons/CircleChevronRightIcon/CircleChevronRightIcon';
import { Colors } from 'enums/colors';

import Button from '../../../common/Button/Button';

const YourCarBanner = () => {
  return (
    <div className="flex flex-col-reverse justify-start items-center lg:items-start mx-auto lg:px-12 lg:py-12 lg:h-[450px] xl:h-[500px] 2xl:h-[545px] lg:bg-[url('/images/your-car.png')] lg:bg-left lg:bg-cover lg:bg-no-repeat md:bg-none ">
      <div className="lg:w-1/3 h-full flex flex-col items-center lg:items-start lg:justify-center mt-[14px]">
        <h2 className="md:leading-10 md:mb-3 md:text-3xl lg:text-4xl text-blue-100 text-center lg:mb-0 lg:leading-none leading-none lg:text-left">
          Your car,
        </h2>
        <h2 className="md:leading-10 md:mb-3 md:text-3xl lg:text-4xl text-blue-100 text-center lg:mb-0 lg:leading-none leading-none lg:text-left">
          your way.
        </h2>
        <p className="text-[#797979] mt-4 lg:text-[20px] xl:text-[24px] w-4/6 md:w-4/6 text-center lg:text-left lg:w-4/6 lg:leading-normal">
          Choose from Australia's largest range of subscription cars
        </p>
        <Link prefetch={false} href="/subscription-cars" passHref>
          <div className="mt-6 mb-4 lg:mb-0 lg:flex lg:items-end lg:justify-start lg:mt-6">
            <Button
              className="flex items-center justify-center"
              color={Colors.green}
            >
              <span className="mr-2">BROWSE CARS</span>
              <CircleChevronRightIcon className="w-5 h-5" />
            </Button>
          </div>
        </Link>
      </div>
      <div className="lg:hidden">
        <img
          className="p-0 object-cover object-center w-full"
          src="/images/your-car-mob.png"
          alt="Three slices of different cars completing each other"
          width={866}
          height={410}
        />
      </div>
    </div>
  );
};

export default YourCarBanner;
