import { BadgeCheckIcon } from '@components/common/Icons';
import cx from 'classnames';

function CheckItemsBenefitsBanner({
  items,
  style,
}: {
  items: string[];
  style?: string;
}) {
  return (
    <div className={cx(`mt-7 md:mt-0 flex flex-col gap-4 font-medium`, style)}>
      {items.map(item => (
        <div key={item} className="flex items-center">
          <BadgeCheckIcon className="h-6 w-6 text-[#F05323] mr-3" />
          <p>{item}</p>
        </div>
      ))}
    </div>
  );
}

export default CheckItemsBenefitsBanner;
