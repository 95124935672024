import Link from 'next/link';

import SectionWithContainer from '@components/common/SectionWithContainer/SectionWithContainer';
import Slider from '@components/common/Slider/Slider';
import brands from 'constants/Brands';

const BrandSection = () => {
  return (
    <SectionWithContainer className="bg-blue-5 py-14 max-w-[100vw]">
      <p className="pb-6 font-medium text-center text-dark-blue-100">
        BROWSE BY BRAND
      </p>
      <Slider
        styles={{
          navigationBackButton: { top: '-68px', left: '5px' },
          navigationNextButton: { top: '-68px', right: '5px' },
        }}
      >
        {brands.map(brand => (
          <Link
            prefetch={false}
            href={`/subscription-cars?make=${brand}`}
            passHref
            key={brand}
          >
            <a className="h-8 flex mx-[2px] lg:mx-0 px-5 items-center text-white justify-center bg-dark-blue-100 rounded-full whitespace-nowrap flex-shrink-0 min-w-[111px]">
              {brand.toUpperCase()}
            </a>
          </Link>
        ))}
      </Slider>
    </SectionWithContainer>
  );
};

export default BrandSection;
