import CircleChevronRightIcon from '@components/common/Icons/CircleChevronRightIcon/CircleChevronRightIcon';
import { Colors } from 'enums/colors';

import Button from '../../../common/Button/Button';
import CheckItemsBenefitsBanner from '../components/CheckItemsBenefitsBanner';

const BydEvSubscriptionBanner = () => {
  return (
    <div className="flex flex-col-reverse justify-start items-center lg:items-start mx-auto lg:px-12 lg:py-8 lg:h-[450px] xl:h-[500px] 2xl:h-[545px] lg:bg-[url('/images/byd-ev.png')] lg:bg-left lg:bg-cover lg:bg-no-repeat xl:bg-right md:bg-none ">
      <div className="h-full flex flex-col items-center lg:items-start lg:justify-start mt-[14px] lg:mt-0">
        <div className="lg:text-left text-center lg:flex lg:items-center space-x-4">
          <h2 className="text-3xl lg:text-4xl text-blue-100 leading-none">
            BYD EV subscriptions
          </h2>
          <h4 className="text-[36px] lg:text-3x1 xl:text-[48px] leading-snug lg:leading-none text-blue-100 font-light">
            from $299 per week
          </h4>
        </div>
        <p className="text-[#797979] mt-4 ml-14 lg:ml-0 lg:text-[15px] xl:text-[18px] text-left lg:text-left lg:1/2 xl:w-2/5 lg:leading-normal">
          Drive the world's best-selling electric vehicles for one flexible,
          all-inclusive weekly payment*
        </p>

        <div className="mt-6 lg:mt-5 flex flex-col-reverse items-center lg:items-start lg:flex-col lg:w-full">
          <CheckItemsBenefitsBanner
            items={[
              '4 month minimum term',
              '500km weekly allowance',
              'Fully maintained',
            ]}
          />
          <div className="md:mb-6 flex items-end justify-start lg:mt-6">
            <Button
              className="hidden lg:flex items-center justify-center"
              color={Colors.green}
              href="https://byd.simplr.com.au/"
              target="_blank"
            >
              <span className="mr-2">SUBSCRIBE NOW</span>
              <CircleChevronRightIcon className="w-5 h-5" />
            </Button>

            <Button
              className="flex lg:hidden items-center justify-center"
              color={Colors.green}
              href="/subscription-cars"
              target="_blank"
            >
              <span className="mr-2">BROWSE CARS</span>
              <CircleChevronRightIcon className="w-5 h-5" />
            </Button>
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <img
          className="p-0 object-cover object-center w-full"
          src="/images/byd-ev-mob.png"
          alt="Two BYD EV cars"
          width={866}
          height={410}
        />
      </div>
    </div>
  );
};

export default BydEvSubscriptionBanner;
